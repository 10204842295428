<template>
  <section id="Raiderettes">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
import ExperiencesCarousel from "@/components/global/ExperiencesCarousel";

export default {
  name: "Raiderettes",
  components: {
    ExperiencesCarousel,
  },
  data() {
    return {
      carouselImages: [
        {
          image: "Experiences/raiderettes-2.jpg",
          title: "Raiderettes",
          description:
            "Football's Fabulous Females, the Raiderettes, have graced the sidelines during Raiders home games since their formation in 1961. Create exciting and memorable moments for each guest by adding a Raiderette photo opportunity to any event.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>